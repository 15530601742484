/**
 *  将数字四舍五入, 保留两位小数
    toFixed() 和 Math.round((num) * 100) / 100 存在错误问题, 如:
    1.555.toFixed(2) => "1.55"
    1.005.toFixed(2) => "1.00"
    Math.round(1.005 * 100) / 100 => 1
 */
export function numberRoundTwo(num) {
  return numberRound(num, 2)
}

export function numberRound(num, precision) {
  precision = Math.pow(10, precision)
  return Math.round((num + Number.EPSILON) * precision) / precision
}
