<template>
  <div>
    <a-spin :spinning="loading">
      <a-row type="flex">
        <a-col
          :flex="1"
          class="iotplt-card-box header-card"
        >
          <a-row>
            <a-col :span="16">
              <p class="column-name">
                账户余额
              </p>
              <p class="column-value">
                {{ feeInfo.balance | formatCurrency }}
              </p>
            </a-col>
            <a-col :span="6" v-if="isSimiotContractingParty">
              <router-link :to="{ name: 'balance_recharge'}">
                <a-button type="primary">
                  去充值
                </a-button>
              </router-link>
            </a-col>
          </a-row>
        </a-col>

        <a-col
          :flex="1"
          class="iotplt-card-box header-card"
          v-for="(info, index) in data"
          :key="index"
        >
          <a-row>
            <a-col :span="18">
              <p class="column-name">
                {{ info.name }}
              </p>
              <p class="column-value">
                {{ info.value | formatCurrency }}
              </p>
            </a-col>
            <a-col :span="6" class="icons">
              <a-icon type="transaction" :style="info.style" />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'AgentPurchaseItemCartMoneyInfo',
  props: {
    feeInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    orderFee: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    // 主体是否有限公司
    isSimiotContractingParty: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    data() {
      return [
        { name: '信用额度', value: this.feeInfo.credit, style: { color: 'rgb(95, 166, 248)' }},
        { name: '锁定金额', value: this.feeInfo.locked, style: { color: 'rgb(236, 96, 115)' }},
        { name: '可用额度', value: this.feeInfo.available, style: { color: 'rgb(59, 135, 123)' }},
        { name: '订单金额', value: this.orderFee, style: { color: 'rgb(191, 150, 228)' }}
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.header-card {
  margin-right: 15px;
  .column-name {
    font-weight: bold;
  }
  .column-value {
    word-break: break-all;
  }
}
.icons {
  color: @primary-color;
  font-size: 50px;
}
</style>
