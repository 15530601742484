<template>
  <div>
    <agent-purchase-item-cart-money-info
      :fee-info="feeInfo"
      :order-fee="orderFee"
      :loading="loading"
      :is-simiot-contracting-party="isSimiotContractingParty"
    />

    <div class="iotplt-content-box item-list">
      <div class="iotplt-table-operation-buttons">
        <a-space>
          <a-popover trigger="hover" placement="left">
            <template slot="content">
              <p>*可用额度=账户余额+信用额度-锁定金额</p>
              <p>*账户可用额度要大于等于订单总额，才能提交订单</p>
              <p>*订单提交后，平台会在一个工作日内进行审核，客服人员可能会与您确认订单信息</p>
            </template>
            <a-icon type="question-circle" />
          </a-popover>
          <a-button :selected-row-keys="selectedRowKeys" type="primary" @click="toSettlement">结算</a-button>
          <destroy-agent-purchase-item :selected-row-keys="selectedRowKeys" @completed="fetchData" />
        </a-space>
      </div>

      <a-table
        size="middle"
        :columns="columns"
        :data-source="items"
        :loading="loading"
        :scroll="{ x: 'max-content' }"
        :pagination="false"
        :row-selection="{ selectedRowKeys: selectedRowKeys,
                          onChange: onSelectChange }"
        :custom-row="customItemRow"
        row-key="id"
      >
        <div
          slot="product_name"
          slot-scope="product_name, record"
        >
          <span v-if="record.valid">
            {{ product_name }}
          </span>
          <a-space v-else>
            <a-tooltip>
              <template slot="title">
                该产品或卡品不可采购
              </template>
              <a-icon type="warning" style="color: #ec5c5c" />
            </a-tooltip>
            {{ product_name }}
          </a-space>
        </div>

        <div
          slot="service_period"
          slot-scope="service_period, record, index"
        >
          <a-spin :spinning="record.loading === true">
            <a-input-number
              :min="1"
              :max="servicePeriodMaxValue"
              :step="1"
              :precision="0"
              style="width: 120px"
              :disabled="!record.valid"
              :formatter="parseServicePeriodInputValue"
              :parser="parseServicePeriodInputValue"
              @change="(servicePeriod) => handleServicePeriodChange(servicePeriod, record, index)"
              v-model="record.service_period"
            />
          </a-spin>
        </div>

        <div
          slot="card_count"
          slot-scope="card_count, record, index"
        >
          <a-spin :spinning="record.loading === true">
            <a-input-number
              :min="1"
              :max="cardCountMaxValue"
              :step="1"
              :precision="0"
              style="width: 120px"
              :disabled="!record.valid"
              :formatter="parseCardCountInputValue"
              :parser="parseCardCountInputValue"
              @change="(cardCount) => handleCardCountChange(cardCount, record, index)"
              v-model="record.card_count"
            />
          </a-spin>
        </div>
      </a-table>

      <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
      />

    </div>
  </div>
</template>

<script>
import AgentPurchaseItemCartMoneyInfo from '@/views/agent_purchase_items/cart/MoneyInfo'
import Pagination from '@/components/Pagination'
import { findCartInfo, updateAgentPurchaseItemFee } from '@/api/agent_purchase_item'
import DestroyAgentPurchaseItem from '@/views/agent_purchase_items/cart/Destroy'
import { formatFloat } from '@/utils/filter'
import { numberRoundTwo } from '@/utils/math'

export default {
  name: 'AgentPurchaseItemCart',
  components: {
    Pagination,
    AgentPurchaseItemCartMoneyInfo,
    DestroyAgentPurchaseItem
  },
  data() {
    this.calculateProductFee = this.$lodash.debounce(this.calculateProductFee, 400)
    return {
      data: [],
      items: [],
      servicePeriodMaxValue: 10000000,
      cardCountMaxValue: 10000000,
      feeInfo: {},
      loading: true,
      selectedRowKeys: [],
      orderFee: 0,
      pagination: {
        total_count: 0
      },
      query: {},
      isSimiotContractingParty: false
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '套餐名称',
          dataIndex: 'product_name',
          scopedSlots: { customRender: 'product_name' },
          fixed: 'left'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '卡品',
          dataIndex: 'card_type'
        },
        {
          title: '服务周期数(个)',
          dataIndex: 'service_period',
          scopedSlots: { customRender: 'service_period' }
        },
        {
          title: '采购卡数(张)',
          dataIndex: 'card_count',
          scopedSlots: { customRender: 'card_count' }
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat,
          scopedSlots: { customRender: 'distributor_price' }
        },
        {
          title: '卡板价格',
          dataIndex: 'card_type_price'
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'product_fee' }
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'card_fee' }
        },
        {
          title: '小计(元)',
          dataIndex: 'total_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'total_fee' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150,
          ellipsis: true
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.calculateOrderFee()
    },

    // 自定义项目行样式
    customItemRow(record) {
      return {
        style: {
          color: record.valid ? '' : '#c7c7c7' // 无效的项目字体颜色
        }
      }
    },

    calculateProductFee(record, index) {
      // 设置当前 item loading 状态
      this.items[index].loading = true
      this.$set(this.items, index, this.items[index])

      if (record.service_period && record.card_count) {
        const data = {
          agent_id: this.$store.getters.userAgentId,
          service_period: record.service_period,
          card_count: record.card_count
        }
        updateAgentPurchaseItemFee(record.id, data).then((res) => {
          if (res.code === 0) {
            this.$lodash.set(this.items, `[${index}].product_fee`, res.data.product_fee)
            this.$lodash.set(this.items, `[${index}].card_fee`, res.data.card_fee)
            this.$lodash.set(this.items, `[${index}].total_fee`, res.data.total_fee)
            this.$lodash.set(this.items, `[${index}].distributor_price`, res.data.distributor_price)
            this.$lodash.set(this.items, `[${index}].card_type_price`, res.data.card_type_price)

            // 设置当前 item loading 状态
            this.items[index].loading = false
            this.$set(this.items, index, this.items[index])

            // 勾选的项目包含当前项目, 才需要计算订单金额
            if (this.selectedRowKeys.includes(record.id)) {
              this.calculateOrderFee()
            }
          } else {
            this.items[index].loading = false
            this.$set(this.items, index, this.items[index])
          }
        })
      }
    },

    calculateOrderFee() {
      let orderFee = 0
      this.selectedRowKeys.forEach((id) => {
        orderFee += numberRoundTwo(this.$lodash.find(this.items, { id: id }).total_fee)
      })
      this.orderFee = numberRoundTwo(orderFee)
    },

    parseServicePeriodInputValue(val) {
      val ||= 1
      if (val < 1) {
        val = 1
      } else if (val > this.servicePeriodMaxValue) {
        val = this.servicePeriodMaxValue
      }
      return parseInt(val)
    },

    parseCardCountInputValue(val) {
      val ||= 1
      if (val < 1) {
        val = 1
      } else if (val > this.cardCountMaxValue) {
        val = this.cardCountMaxValue
      }
      return parseInt(val)
    },

    // 处理改变服务周期值
    handleServicePeriodChange(servicePeriod, record, index) {
      this.calculateProductFee(record, index)
    },

    // 处理改变采购卡数值
    handleCardCountChange(cardCount, record, index) {
      this.calculateProductFee(record, index)
    },

    // 是否选择了无效的采购项目
    isInvalidItemSelected() {
      const invalidIds = this.$lodash.map(this.items.filter(item => !item.valid), 'id')
      return this.selectedRowKeys.some(function(key) {
        if (invalidIds.includes(key)) {
          return true
        }
      })
    },

    toSettlement() {
      if (this.selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择采购项',
          content: ''
        })
      } else if (this.isInvalidItemSelected()) {
        this.$warning({
          title: '请勿勾选无效的项目',
          content: ''
        })
      } else if (this.feeInfo.available < this.orderFee) {
        this.$warning({
          title: '可用额度小于订单金额，无法提交订单',
          content: ''
        })
      } else {
        this.$router.push({ name: 'cart_settlement', query: { ids: this.selectedRowKeys.join(',') }})
      }
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      this.orderFee = 0
      findCartInfo(
        Object.assign({ agent_id: this.$store.getters.userAgentId }, this.query)
      ).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.items = res.data.agent_purchase_items
          this.feeInfo = res.data.fee_info
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-list {
  margin: 20px 15px 0 0;
}

</style>
